@font-face {
  font-family: 'ABC Synt';
  src: url('./fonts/abcsynt-regular.woff2') format('woff2'),
    url('./fonts/abcsynt-regular.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: 'ABC Synt';
  src: url('./fonts/abcsynt-turbo.woff2') format('woff2'),
    url('./fonts/abcsynt-turbo.woff') format('woff');
  font-style: italic;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Antique Legacy';
  src: url('./fonts/antique-legacy-light.woff2') format('woff2'),
    url('./fonts/antique-legacy-light.woff') format('woff');
  font-style: light;
  font-weight: 200;
  font-display: swap;
}
@font-face {
  font-family: 'Antique Legacy';
  src: url('./fonts/antique-legacy-regular.woff2') format('woff2'),
    url('./fonts/antique-legacy-regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

.tf-logo-header {
  position: relative;
  width: 164px;
  height: 16px;
  flex-shrink: 0;
  fill: currentColor;
}

/* Base styles for desktop screens */

.mobile-intro-logos-container {
  display: inline-flex;
  align-items: center;
  gap: 24px;
}

.mobile-intro-logos-tf {
  width: 135px;
  height: 13.465px;
}

.mobile-intro-logos-x {
  width: 17px;
  height: 16.971px;
}

.mobile-intro-logos-twp {
  width: 101px;
  height: 43px;
}

.landscape-fallback-image {
  display: flex;
  width: 39px;
  height: 58px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.share-button,
.start-button {
  height: 53px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;

  border-radius: 110px;
  border: 1px solid var(--white-on-video-stroke, rgba(255, 255, 255, 0.66));
  background: var(--white-on-video, rgba(251, 251, 254, 0.88));

  /* video hover */
  box-shadow: 2px 4px 14px 0px rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(24px);
}

.share-button {
  display: flex;
  left: 50%;
  bottom: 50px;
  transform: translateX(-50%);
  position: absolute;
}

.start-button-text,
.share-button-text {
  color: var(--Ink-1, #1e1e1e);
  /* 14 regular */
  font-family: Antique Legacy;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.56px;
}

.share-button-arrow {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.video-player {
  width: 100%;
  left: 0px;
  bottom: 0px;
  height: 100vh;
  position: absolute;
}


/* Add this to your styles.css or equivalent stylesheet */
.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3); /* Light grey border */
  border-top: 4px solid #ffffff; /* White top border */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Tailwind CSS */
.desktop-fallback-title-line-height {
  line-height: 1.0; /* Adjust this value as needed */
}
