/* ./src/index.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.border-animate {
  animation: spin-border 60s linear infinite;
  border-color: transparent;
  box-sizing: border-box; /* This ensures the border width doesn't add to the element's total width and height */
}

@keyframes spin-border {
  0% {
    transform: rotate(0deg);
    border-color: transparent;
  }
  25% {
    border-color: blue transparent transparent transparent;
  }
  50% {
    border-color: blue blue transparent transparent;
  }
  75% {
    border-color: blue blue blue transparent;
  }
  100% {
    border-color: blue;
  }
}

@import 'tailwindcss/utilities';
